/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, Dispatch, useContext, useMemo, useReducer } from "react";
import { CourseItem } from "../models/courses/CourseItem";

export enum CourseContextActionTypes {
  SET_LOADING = "SET_LOADING",
  SET_COURSE = "SET_COURSE",
  SET_COURSE_LIST_SLUG = "SET_COURSE_LIST_SLUG"
}

interface CoursesState {
  learnsterCourse: {
    id: string;
    name: string;
    childCourses: CourseItem[];
  } | null;
  loading?: boolean;
  courseListSlug?: string;
}

type CoursesAction = Partial<CoursesState> & {
  type: CourseContextActionTypes;
};

type CoursesContextType = {
  state: CoursesState;
  dispatch: Dispatch<CoursesAction>;
};

const initialCoursesState = {
  learnsterCourse: null,
  loading: false
};

const coursesReducer = (state: CoursesState, action: CoursesAction) => {
  const result = { ...state };

  switch (action.type) {
    case CourseContextActionTypes.SET_COURSE:
      result.learnsterCourse = action.learnsterCourse ?? null;
      break;

    case CourseContextActionTypes.SET_LOADING:
      result.loading = action.loading;
      break;

    case CourseContextActionTypes.SET_COURSE_LIST_SLUG:
      result.courseListSlug = action.courseListSlug;
      break;

    default: {
      const _check: never = action.type;
      return result;
    }
  }

  return result;
};

const CoursesContext = createContext<CoursesContextType>({
  state: initialCoursesState,
  dispatch: () => null
} as CoursesContextType);

const CoursesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(coursesReducer, initialCoursesState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <CoursesContext.Provider value={contextValue}>{children}</CoursesContext.Provider>
  );
};

const useCoursesContext = () => useContext(CoursesContext);

const fetchCourseData =
  (dispatch: Dispatch<CoursesAction>) =>
  async (mainCourseId: string, linkedCourseIds?: string[]) => {
    dispatch({ type: CourseContextActionTypes.SET_LOADING, loading: true });

    try {
      const response = await fetch(
        [
          process.env.GATSBY_AZURE_COURSES_API_URL,
          "courses",
          linkedCourseIds?.length ? "grouped" : null,
          mainCourseId +
            (linkedCourseIds?.length ? `?ids=${linkedCourseIds.join(",")}` : "")
        ]
          .filter(Boolean)
          .join("/")
      );

      if (response.ok) {
        const json = (await response.json()) as CoursesState["learnsterCourse"];
        dispatch({
          type: CourseContextActionTypes.SET_COURSE,
          learnsterCourse: json
        });
      }
    } catch (error) {
      console.error("Failed to fetch course data:", error);
    } finally {
      dispatch({ type: CourseContextActionTypes.SET_LOADING, loading: false });
    }
  };

const resetCoursesState = dispatch => {
  dispatch({ type: CourseContextActionTypes.SET_COURSE, course: null });
  dispatch({ type: CourseContextActionTypes.SET_LOADING, loading: false });
};

export { fetchCourseData, resetCoursesState };
export { CoursesContext, CoursesContextProvider, useCoursesContext };
